/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import strapiCloud from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-cloud_strapi-admin.js?v=abcb69e2";
import seo from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-seo_strapi-admin.js?v=abcb69e2";
import usersPermissions from "/admin/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=abcb69e2";
import { renderAdmin } from "/admin/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=abcb69e2";

import customisations from "/admin/src/admin/app.tsx";

renderAdmin(document.getElementById("strapi"), {
  customisations,

  plugins: {
    "strapi-cloud": strapiCloud,
    seo: seo,
    "users-permissions": usersPermissions,
  },
});
